.hero_section {
    padding-top: 150px;
}

.hire_btn {
    background-color: #fff !important;
    color: #0e1b31 !important;

}
.hero_btns a{
    text-decoration: none;
    font-weight: 500;
    color:#fff;
    
}
.hire_btn a {
    color:#7865ff
}

.hire_btn:hover a {
    color: #0e1b31
}

.hero_title span {
    color: #7865ff;

}

.hero_title {
    font-weight: 600;
}

.hero_img {
    width: 300px;
    height: 358px;
    border-radius: 10px;
    background-color: #7865ff;
    margin-left: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.hero_img img {
    width: 105%;
}

/* =======media query====== */
@media (max-width:992px) {
    .hero_img {
        width: 250px;
        height: 250px;
        margin-top: 100px;
    }
    .hero_img img {
        width: 87.8%;
        margin-left: 20px;
    }

    .hero_content p {
        font-size: .8rem
    }

    .hero_btns {
        
        padding: 5px 20px;
        font-size: .8rem;
    }
}

@media (max-width:576px) {
    .hero_img {
        margin: auto;
    }

    .hero_content {
        margin-bottom: 50px;
    }

    .hero_title {

        font-size: 1.5rem;
    }
 
}

