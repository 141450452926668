.single_experience_container {
    padding: 30px;
    background-color: #132347;
    border-radius: 10px;
}

.single_experience {
    position: relative;
    padding: 10px 30px;
    z-index: 79;
}

.single_experience::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    content: "";
    background: #7865ff;
    color: #7865ff;
    z-index: 80;
}

.experience_icon {
    position: absolute;
    top: 5px;
    left: -10px;
    color: #7865ff;
    font-size: 1.2rem;
    z-index: 80;
}

.single_experience h6 {
    font-size: .8rem;
    opacity: 75%;
    font-weight: 300;
    margin-bottom: 10px;

}

.single_experience h5 {
    font-size: 1.1rem;
    margin-bottom: 15px;

}

.single_experience ح {
    font-size: .9rem;
    line-height: 30px;
    font-size: .9rem;


}

@media(max-width:992px) {
    .single_experience h2 {
        font-size: .7rem;
    }

    .single_experience h5 {
        font-size: .9rem;
        margin-bottom: 10px;
    }

    .single_experience {
        font-size: .7rem;
        line-height: 28px
    }

    .single_experience_container {
        margin-bottom: 40px;
    }
}