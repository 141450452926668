.about_content_wrapper{
    background: #132347;
    padding: 20px 20px;
    border-radius: 10px;
}
.about_content h2{
    margin-bottom: 15px;
}
.about_content p{
    line-height:30px;
    font-weight: 300;
}
.about_btns{
    background-color: #102048;
    border-radius: 10px;
    
}
.about_btn{
    border: none;
    outline:none;
    width: 100%;
    padding: 20px;
    color:#fff;
    background:transparent;
    border-bottom: 1px solid #7865ff;
    border-radius: 5px 5px 0px 0px;
}
.about_btn_active{
    background: #7865ff;
}
.about_btn:last-child{
    border-bottom: none;
    border-radius: 0px
}
.social_links span a{
    text-decoration: none
}
.social_links span a i{
    color:#7865ff
}
.social_links span {
    margin-top: 10px;
    margin-right: 15px;
}

.about_img{
    background: #7865ff;
    border-radius: 10px;
    height:250px;
    width: 20%;
}
.about_img img{
    width: 128%;
    text-align: center;
    margin-left: -5px;
}


/* ======media query========= */

@media(max-width:992px){
    .about_btn{
        padding: 10px 25px;
        font-size: .8rem;
    }
    .about_content h2{
        font-size:1.5rem;
    }
  
    .about_content p{
        font-size:.8rem;
        line-height:28px
    }
    .about_img{
        height: 150px;
    }
    .about_btns{
        margin-bottom: 40px;
    }
}

@media(max-width:576px){
    .aboutAll{
        display: flex;
        flex-direction: column;
    }
    .about_img{
        width: 70%;
        margin: auto;
    }
    .about_img img{
        width: 84%;
        text-align: center;
        margin-left: 25px;
    }
    .about_content{
        margin-top: 20px;
    }
}













