.header {
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}

.logo h5 {
    font-size: 1.3rem;
    color: #7865ff;
}

.nav_list {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
}

.nav_item a {
    color: #fff;
    transition: .3s;
    text-decoration: none;
    font-weight: 400;
    cursor: pointer;
}

.nav_item a:hover {
    color: #7865ff
}

.btn {
    background-color: #7865ff !important;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: .9rem;
    padding: 7px 25px;
}
.nav_right button a {
    color: #fff;
    text-decoration: none
}

.btn:hover {}

.mobile_menu i {
    color: #fff !important;
    font-size: 1.6rem !important;
    cursor: pointer;
}

.mobile_menu {
    display: none;
}

.header_shrink {
    background-color: #132347;
    box-shadow: 2px 2px 4px #7765ffac
}




/* =========media query========= */
@media (max-width:992px) {
    .nav_menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #7765ff69;
        z-index: 8888888888;
        display: none;
        transition: .3s;
    }

    .nav_list {
        position: absolute;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        background: #132347;
        z-index: 9999999999;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
    }

    .menu_active {
        display: block;
    }

    .mobile_menu {
        display: block;
        cursor:pointer
    }

    .btn {
        display: none !important;
    }
    .header{
        height:50px;
        line-height:50px
    }
}