.design_top {
    color: #7865ff;
    margin-bottom: 15px;
}

.design_card {
    position: relative;
    z-index: 90;
    overflow: hidden;

}

.design_card:hover .design_content {
    transform: translateY(0);
    opacity: 1
}


.design_img img {
    border-radius: 5px;
    width: 100%;
}

.hide {
    display: none;
}

.show {
    display: block
}

.btn {
    color: #fff !important;
    font-weight: 500;

}

.showBtn {
    display: block !important;
}

img {
    width: 100%;
}

.img {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.allImages {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.spaceGirl {
    grid-column: 1/3;

}

.PATRONUS {
    grid-column: 4/6;
}

.crackedGirl {
    grid-column: 2/4
}

.childCave {
    grid-column: 4/6
}

.storm {
    grid-column: 3/5
}

.glass {
    grid-column: 3/6
}

.gamingPoster {
    grid-column: 1/6
}
.crackedSoul{
    grid-column: 2/4
}

.amir{
    grid-column:2/4
}

