.skills_wrapper{
    /* background: #132413; */
    padding: 20px;
    border-radius: 10px;
}
.skill_bar{
    width: 100%;
    height:5px;
    position: relative;
    background:#c1ceff;
    border-radius: 50px;
    z-index: 999;
}
.skill_bar_percentage{
    background:#7865ff;
   
    height: 5px;
    position: absolute;
    z-index: 1000;
    top:0;
    left:0;
    border-radius: 50px;
}
.skill_title span{
    color:#fff

}
.skill_title{
    margin-bottom: 10px; 
}


@media(max-width:992px){
    .skill_title h6{
        font-size:.8rem;

    }
    .skill_title span{
        font-size:.7rem;

    }
}






















