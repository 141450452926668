
.form{
    width: 100%;
    background:#132347;
    padding: 30px;
    border-radius: 5px;
}
.form_input input,
.form_input textarea{
    width: 100%;
    padding: 10px 15px;
    color:#fff;
    margin-bottom: 15px;
    background: #102048;
    border:none;outline: none;
    border-radius: 10px;
    font-size:.8rem;
}
.submit_button{
    margin-top: 15px;
    padding: 7px 25px;
    font-size: .9rem;
    background: #7865ff;
    cursor:pointer;
    border:none;outline: none;
    border-radius: 5px;
}



@media(max-width:992px){
    .submit_button{
        padding: 5px 25px;
        font-size:.8rem
    }
}























