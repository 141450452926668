.countDown {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.clock {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(270deg, #051817, #0e3839);
    color: #06f1f6;
    text-shadow: 1px 1px 7px;
    border-radius: 15px;
    border: 1px solid silver;
    box-shadow: 0 0 5px 1px;
}

.clock section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.clock section p {
    font-size: 30px;
}

.clock section small {
    color: silver;
    text-shadow: none;
}


@media(max-width:1024px){
    .clock section {
        padding: 5px;
    }
    
    .clock section p {
        font-size: 30px;
    }
}
