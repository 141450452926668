@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
}

body{
  background-color:#0e1b31 !important;
}
html{
  scroll-behavior: smooth;
}
h1,h2,h3,h4,h5,h6{
  color:#fff;
  font-family: "lato", sans-serif;
  margin: 0;
}
h1,h2{
  font-size:2rem;
  font-weight:500
}

p{
  color:#fff;
  opacity:75%;
  font-family:"Rubik",sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

ul{
  list-style:none;
  margin: 0;
}

a{
  color:unset;
  font-family: "lato", sans-serif;
  text-decoration:none;
}

a:hover{
  color:unset
}

section,footer{
    padding: 60px 0;
}

::-webkit-scrollbar{
  width: 10px;
  background:#c1ceff
}
::-webkit-scrollbar-thumb{
  border-radius:2em;
  background:#282f4e
}


@media(max-width:992px){
  section{
    padding: 40px 0;
  }
}











