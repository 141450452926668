.table{
    border:none !important;
    border-collapse:collapse;
    border:1px solid #132347 !important;
    border-radius: 5px !important;
    table-layout:fixed;
    font-size:5rem
}
th,td{
    padding: 1.3rem 1rem !important;
    font-size:1rem !important
}
thead{
    background:#7865ff !important;
    color:#fff
}
td{
    font-size:.8rem;
    color:#fff;
    font-weight: 400;
    max-height: 10 !important;
}
tr:nth-child(even){
    background:#102048;
}

@media(max-width:992px){
    th,td{
        font-size:.7rem;
        padding: 0.8em;
    }
}
@media(max-width:576px){
    th,td{
        font-size:.6rem !important;
        padding: 0.2rem !important;
    }
}



























